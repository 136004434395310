import React from 'react'

const asButton = Comp => ({ className, small, variant, ...props }) => {
  function getVariantStyles() {
    switch (variant) {
      case 'muted':
        return 'bg-gray-200 text-gray-800 border-2 border-gray-200 hover:border-gray-300'
      case 'primary':
      default:
        return 'bg-gray-800 text-white border border-gray-800 hover:bg-gray-900'
    }
  }

  return (
    <Comp
      className={`font-heading uppercase px-6 py-${
        small ? 1 : 2
      } rounded cursor-pointer inline-block ${getVariantStyles()} ${className}`}
      {...props}
    />
  )
}

asButton.defaultProps = {
  className: '',
  small: false,
  variant: 'primary',
}

export { asButton }
