import React from 'react'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../ButtonLink'
import { RawContentSection } from '../RawContentSection'

const Reviews = ({ data: { feedback, title } }) => (
  <section id="reviews" className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
    <div className="container max-w-3xl">
      <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
      <div className="my-8">
        {feedback.map(({ name, text }) => (
          <div key={name} className="italic border-4 border-white rounded p-4 md:p-8 mb-4">
            <RawContentSection noFontSize content={<RichText render={text} />} width="3xl" />
            <p className="text-right font-bold text-sm">- {name}</p>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <ButtonLink to="/reviews">Feedback, Reviews &amp; links</ButtonLink>
      </div>
    </div>
  </section>
)

export { Reviews }
