import React from 'react'
import { Link } from '@reach/router'

import { asButton } from './asButton'

const Comp = props => (props.to ? <Link {...props} /> : <a {...props} />)

const ButtonLink = asButton(Comp)

export { ButtonLink }
