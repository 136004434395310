import React from 'react'
import { RichText } from 'prismic-reactjs'

import { Image } from '../Image'
import { ButtonLink } from '../ButtonLink'

const Extra = ({ mainImage, shortDescription, title }) => (
  <div className="mt-4 w-full max-w-3xl mx-auto md:max-w-none md:w-1/3 md:px-4">
    <h3 className="text-xl">{RichText.asText(title)}</h3>
    <div className="mt-4">
      <Image image={mainImage} alt={RichText.asText(title)} title={RichText.asText(title)} />
    </div>
    <div className="mt-4">
      <RichText render={shortDescription} />
    </div>
  </div>
)

const Extras = ({ data: { extras, title } }) => (
  <div className="bg-white px-4 py-8 md:py-12 lg:py-16 border-b-4 border-gray-200">
    <div className="container max-w-6xl">
      <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
      <div className="flex justify-center md:justify-start flex-wrap md:-mx-4">
        {extras.map(({ title, ...rest }) => (
          <Extra key={RichText.asText(title)} title={title} {...rest} />
        ))}
      </div>
      <div className="mt-8">
        <ButtonLink to="/extras">More info</ButtonLink>
      </div>
    </div>
  </div>
)

export { Extras }
