import React from 'react'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../ButtonLink'
import { Image } from '../Image'
import { RawContentSection } from '../RawContentSection'

const Wood = ({ data: { image1, image2, summary, title } }) => (
  <section id="wood" className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
    <div className="container max-w-xl lg:max-w-3xl">
      <div>
        <h2 className="text-5xl lg:text-6xl">{RichText.asText(title)}</h2>
        <div className="mt-4">
          <RawContentSection content={<RichText render={summary} />} width="3xl" />
        </div>
      </div>
      <div className="mt-6 lg:mt-8">
        <div className="pb-6 md:pb-8 w-full">
          <Image image={image1} alt={RichText.asText(title)} title={RichText.asText(title)} />
        </div>
        <div className="w-full">
          <Image image={image2} alt={RichText.asText(title)} title={RichText.asText(title)} />
        </div>
      </div>
      <div className="mt-8">
        <ButtonLink to="/wood">View the Wood</ButtonLink>
      </div>
    </div>
  </section>
)

export { Wood }
