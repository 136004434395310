import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Extras, ForSale, Maker, Process, Reviews, Wood } from '../components/HomeSections'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

import { toHomeData } from '../transform'

const IndexPage = ({
  data: {
    prismic: { allUkuleles, home_page, global_fields },
  },
}) => {
  const { forSale, process, wood, maker, extras, customerFeedback } = toHomeData({
    for_sale_products: allUkuleles.edges,
    ...home_page,
    ...global_fields,
  })

  return (
    <Layout>
      <SEO title="Home" />
      <h1 className="visually-hidden">Captain Ukuleles</h1>
      <ForSale data={forSale} />
      <Process data={process} />
      <Maker data={maker} />
      <Wood data={wood} />
      <Extras data={extras} />
      <Reviews data={customerFeedback} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allUkuleles {
        ...productPreviewEdges
      }
      global_fields(lang: "en-nz", uid: "global") {
        email_address
      }
      home_page(lang: "en-nz", uid: "home") {
        ...homePage
      }
    }
  }
`

export default IndexPage
