import React from 'react'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../ButtonLink'
import { Image } from '../Image'
import { RawContentSection } from '../RawContentSection'

const Process = ({ data: { title, summary, image1, image2 } }) => (
  <section id="process" className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
    <div className="container max-w-3xl">
      <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
      <div className="mt-4">
        <RawContentSection content={<RichText render={summary} />} width="3xl" />
      </div>
      <div className="flex -mx-2 mt-6 md:mt-8">
        <div className="px-2 w-1/2">
          <Image image={image1} alt={RichText.asText(title)} title={RichText.asText(title)} />
        </div>
        <div className="px-2 w-1/2">
          <Image image={image2} alt={RichText.asText(title)} title={RichText.asText(title)} />
        </div>
      </div>
      <div className="mt-8">
        <ButtonLink to="/process">View the process</ButtonLink>
      </div>
    </div>
  </section>
)

export { Process }
