import React from 'react'

const RawContentSection = ({ center, className, content, noFontSize, width }) => (
  <div
    className={`max-w-${width} ${center ? 'mx-auto' : ''} ${
      !noFontSize ? 'text:lg' : ''
    } raw-html mb-4 ${className}`}
  >
    {content}
  </div>
)

RawContentSection.defaultProps = {
  center: true,
  className: '',
  noFontSize: false,
  width: 'xl',
}

export { RawContentSection }
