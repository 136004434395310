import React from 'react'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../ButtonLink'
import { Products } from '../Products'

const ForSale = ({ data: { email, description, shipping, products, title } }) => {
  return (
    <section className="py-8 md:py-12 lg:py-16 px-4">
      <div className="container">
        <div className="max-w-lg mx-auto text-center">
          <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
          <div className="mt-4">
            <div className="text:lg md:text-xl">
              <RichText render={description} />
            </div>
            <p className="mt-2">
              <a className="hover:underline text-2xl md:text-3xl font-heading" href={`mailto:${email}`}>
                {email}
              </a>
            </p>
            <p className="uppercase mt-3">
              <small>{shipping}</small>
            </p>
            <div className="w-64 border-b-4 border-gray-800 inline-block mt-8 rounded"></div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto">
        <div className="-mx-2 md:-mx-4">
          <Products
            className="mt-8 md:mt-16"
            products={products.sort((a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated))}
          />
        </div>
      </div>
      <div className="md:text-center md:mt-8">
        <ButtonLink to="/ukuleles">View all ukuleles</ButtonLink>
      </div>
    </section>
  )
}

export { ForSale }
