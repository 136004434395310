import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../ButtonLink'

const Maker = ({ data: { image, summary, title } }) => (
  <section id="maker" className="bg-white px-4 py-8 md:py-12 lg:py-16">
    <div className="container max-w-xl">
      <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
      <div className="w-full order-2 mt-4">
        <Img className="w-full" fluid={image} />
      </div>
      <div className="mb-4 mt-6">
        <ButtonLink to="/maker">Find out more</ButtonLink>
      </div>
    </div>
  </section>
)

export { Maker }
